import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import statics from "../../imgs/image 5.png";
import estate from "../../imgs/layer1.png";
import image from "../../imgs/24-Tax.png";
import { Checkbox, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import * as Icon from "react-icons/fi";
import isolation from "../../imgs/Isolation_Mode.png";

const PhoneSeller = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [userNumber, setUserNumber] = useState("");
  const [buyerNumber, setBuyerNumber] = useState("");
  const [error, setError] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [userError, setUserError] = useState("");
  const [buyerError, setBuyerError] = useState("");
  const [open, setOpen] = useState(false)
  const [agreed, setAgreed] = useState(false);
  const [agreedError, setAgreedError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("token")) {
      localStorage.setItem("Token", urlParams.get("token"));
    }
  }, []);

  const validatePhoneNumber = (number) => {
    return /^\d{9}$/.test(number) && number.startsWith("5");
  };

  const validateInputs = (seller, buyer) => {
    if (!validatePhoneNumber(buyer) && buyer) {
      console.log("seellller")
      return setBuyerError(t("text51"));
    }
    if (!validatePhoneNumber(seller) && seller) {
      console.log("seellller")
      return setUserError(t("text50"));
    }
    if (seller === buyer) {
      return setUserError(t("text52"));
    }
    console.log("endd")
    return setBuyerError(null) || setUserError(null);
  };

  console.log("usserrr", userError)
  const handleInputChange = (setter, otherValue, value) => {
    if (/^\d*$/.test(value) && value.length <= 9) {
      setter(value);
      validateInputs(
        setter === setUserNumber ? value : userNumber,
        setter === setBuyerNumber ? value : buyerNumber
      );
    }
  };

  useEffect(() => {
    if (!buyerError) {
      setOpen(true)
    }
  }, [buyerError])



  const handleRequiredFieldsError = () => {
    if (buyerNumber === "") return setBuyerError(t("text70"))
    if (userNumber === "") return setUserError(t("text70"))
    if (!agreed) return setAgreedError(t("text70"))
    return;
  }


  const handleSubmit = () => {
    handleRequiredFieldsError()
    if (userError || buyerError || agreedError) return;
    if (userNumber && buyerNumber && agreed) {
      setIsLoading(true)
      const data = {
        advertiserMobileNumber: "+966" + userNumber,
        buyerNumber: "+966" + buyerNumber,
        token: localStorage.getItem("Token") || "",
      };
      axios
        .put("/leads/update-lead-seller-mobile", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Token")}`,
          },
        })
        .then((response) => {
          setIsLoading(false)
          navigate("thank");
        })
        .catch(
          (error) => {
            setIsLoading(false)
            setRequestError(true)
          }
        );
    }
  };

  useEffect(() => {
    setBuyerError((""))
    setUserError((""))
    setAgreedError((""))
  }, [i18n.language])


  const isArabic = localStorage.getItem("i18nextLng") === "ar";
  return (
    <div className="conataineSeller">
      <div>
        <div>
          <div
            style={{
              padding: "0px 0px",
              marginTop: "0px",
              width:
                localStorage.getItem("i18nextLng") === "ar" ? "100%" : "100%",
              overflow: "hidden",
            }}
          >
            <div style={{ marginTop: "3px", padding: "10px 15px" }}>
              <h4
                style={{
                  color: "#1F2532",
                  fontWeight: "700",
                  fontSize: "21px",
                }}
              >
                {t("text59")}
              </h4>
            </div>

            <div
              style={{
                marginTop: "5px",
                padding: "4px 15px",
                display: "flex",
                gap: "5px",
              }}
            >
              <h6
                style={{
                  color: "#52B44A",
                  fontWeight: "700",
                  fontSize: "21px",
                }}
              >
                {t("text60")}{" "}
                <span
                  style={{
                    color: "#004C51",
                    fontWeight: "700",
                    fontSize: "21px",
                  }}
                >
                  {t("text62")}
                </span>{" "}
              </h6>
            </div>
            <div
              style={{
                marginTop: "7px",
                backgroundColor: "#F5FFF5",
                padding: "10px 15px",
              }}
            >
              <div style={{ marginTop: "10px", padding: "0px 0px" }}>
                <div style={{ display: "flex", gap: "7px" }}>
                  <p>
                    {" "}
                    {t("text32")}{" "}
                    <img src={image} alt="" style={{ marginBottom: "0px" }} />
                  </p>
                </div>

                <div style={{ display: "flex", gap: "7px", marginTop: "5px" }}>
                  <p>
                    {t("text33")} <img src={estate} alt="" />
                  </p>
                </div>

                <div style={{ display: "flex", gap: "7px", marginTop: "5px" }}>
                  <p>
                    {t("text63")}
                    <span style={{ marginX: "5px", color: "#004C51" }}>
                      {t("text64")}
                    </span>
                    <span>{t("text65")}</span>
                    <img src={statics} alt="" style={{ marginBottom: "5px" }} />
                  </p>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "15px" }}>
              <div style={{ display: "flex", gap: "5px" }}>
                <h6 style={{ color: "#4D4D4D" }}><span style={{ color: "red", margin: 4 }}>*</span>{t("text45")}</h6>
                <h6 style={{ color: "#004C51", fontWeight: "bold" }}>
                  {t("text46")}{" "}
                </h6>
              </div>

              <TextField
                disabled={isLoading}
                value={buyerNumber}
                onChange={(e) =>
                  handleInputChange(
                    setBuyerNumber,
                    userNumber,
                    e.target.value.trim()
                  )
                }
                fullWidth
                placeholder="5xxxxxxxx"
                variant="outlined"
                sx={{ marginTop: "10px" }}
                InputProps={{
                  startAdornment: (
                    <span
                      style={{
                        marginLeft: "8px",
                        fontSize: "16px",
                        color: "#000",
                      }}
                    >
                      966
                    </span>
                  ),
                  style: { textAlign: "left", direction: "ltr" },
                }}
                inputProps={{
                  dir: "ltr",
                  style: { textAlign: "left", marginLeft: "10px" },
                }}
              />
              {buyerError && <p style={{ color: "red", marginTop: "5px" }}>{buyerError}</p>}


              {buyerNumber && !buyerError && open && <>      <div style={{ display: "flex", gap: "5px", marginTop: "20px" }}>
                <h6 style={{ color: "#4D4D4D" }}><span style={{ color: "red", margin: 4 }}>*</span>{t("text47")}</h6>
                <h6 style={{ color: "#004C51", fontWeight: "bold" }}>
                  {t("text49")}
                </h6>
              </div>

                <TextField
                  disabled={isLoading}
                  value={userNumber}
                  onChange={(e) =>
                    handleInputChange(
                      setUserNumber,
                      buyerNumber,
                      e.target.value.trim()
                    )
                  }
                  fullWidth
                  placeholder="5xxxxxxxx"
                  variant="outlined"
                  sx={{ marginTop: "10px" }}
                  InputProps={{
                    startAdornment: (
                      <span
                        style={{
                          marginLeft: "8px",
                          fontSize: "16px",
                          color: "#000",
                        }}
                      >
                        966
                      </span>
                    ),
                    style: { textAlign: "left", direction: "ltr" },
                  }}
                  inputProps={{
                    dir: "ltr",
                    style: { textAlign: "left", marginLeft: "10px" },
                  }}
                />

                {userError && <p style={{ color: "red", marginTop: "5px" }}>{userError}</p>}

                {requestError && <p style={{ color: "red", marginTop: "10px" }}>{t("text61")}</p>}</>}
              <div
                style={{ marginBottom: "10px", marginTop: "10px" }}
                className="checkBoxContainer"
              >
                <span style={{ color: "red", fontWeight: "bold", marginRight: isArabic ? "0px" : "-5px", marginLeft: isArabic ? "-5px" : "0px" }}>*</span>
                <Checkbox
                  disabled={isLoading}
                  checked={agreed}
                  onChange={(value, event) => {
                    setAgreed(!agreed)
                    if (!agreed) setAgreedError(false)
                  }}
                  size={"small"}
                  borderColor="#F8F8F8;"
                  style={{
                    color:
                      "#52B44A"
                  }}
                />
                <span
                  onClick={() => {
                    setAgreed(!agreed)
                    if (!agreed) setAgreedError(false)
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {t("text71")}
                </span>
              </div>
              {agreedError && <p style={{ color: "red", marginTop: "-10px" }}>{agreedError}</p>}

              <button
                onClick={handleSubmit}
                className="green-btn"
                style={{
                  background: "var(--Brand-Green, #52B44A)",
                  opacity: userError || buyerError || isLoading ? 0.5 : 1,
                  cursor: userError || buyerError || isLoading ? "not-allowed" : "pointer",
                  height: "51px",
                  marginTop: "23px",
                  width: "100%",
                }}
                disabled={userError || buyerError || isLoading}
              >
                {isLoading ? <CircularProgress style={{ color: "white" }} size={"20px"} /> : t("text37")}
              </button>

            </div>

            <div
              style={{
                width: "100%",
                backgroundColor: "#E1E4EA",
                height: "1px",
                marginTop: "20px",
              }}
            ></div>

            <div
              style={{
                marginTop: "20px",
                backgroundColor: "#F5FFF5",
                padding: "20px 10px",
                textAlign: "center",
              }}
            >
              <h1
                style={{
                  textAlign: "center",
                  color: "#1F2532",
                  fontSize: "24px",
                }}
              >
                {t("text38")}
              </h1>
              <img src={isolation} alt="" />
              <p
                style={{
                  textAlign: "center",
                  color: "#4D4D4D",
                  fontSize: "16px",
                  marginTop: "20px",
                }}
              >
                {t("text67")}
                <span style={{ color: "#004C51" }}>{t("text68")}</span>
                <span>{t("text69")}</span>
              </p>
              <Link to="/start" style={{ textDecoration: "none" }}>
                <button
                  disabled={isLoading}
                  className="green-btn"
                  style={{
                    background: "transparent",
                    opacity: "1",
                    cursor: "pointer",
                    height: "51px",
                    marginTop: "29px",
                    width: "100%",
                    border: "1px solid var(--Brand-Green, #17B26A)",
                    color: "var(--Brand-Green, #17B26A)",
                    borderRadius: "10px",
                    marginLeft: "10px",
                    display: "flex",
                  }}
                  onClick={() => navigate("/start")}
                >
                  {t("text39")}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneSeller;
