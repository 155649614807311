export const translatioanEn = {
  title1: 'Unlock the Best Mortgage Rates for Your Dream Home.',
  infoTitle: 'The max loan amount, monthly installment and rate depends on your:',
  infoItem1: 'Work sector',
  infoItem2: 'Salary',
  infoItem3: 'Government support eligibility',
  infoText: "Let's find out your financing opportunities. We will guide you through the whole process.",
  btn1: 'Start now',
  agree:"I agree to the ",
  termsAndConditions:"terms and conditions.",
  agreeSIMAH:"I agree to retrieve my data from SIMAH",
  title2: 'Please tell us about your work',
  selectButton1: 'Job selection',
  selectBtn1: "Government",
  selectBtn2: "Military",
  selectBtn3: "Private (SME)",
  selectBtn4: "Private (Enterprise)",
  selectBtn5: "Retired",
  Rank: "Rank",
  Pilote: 'Are you a pilot?',
  inputTitle1: 'Employer name',
  placeHolder1: 'Type your name',
  inputTitle2: 'Basic salary',
  placeHolder2: 'Type your salary here',
  inputTitle3: 'Your net monthly salary after deducting insurance',
  placeHolderInfo: "Monthly salary deposited in the bank account after deducting insurance",
  placeHolder3: 'Type your salary here',
  inputTitle4: 'Service start date',
  placeHolder4: 'Type your name',
  inputTitle5: 'Salary bank',
  placeHolder5: 'Select bank',
  selectButton2: "How long you have been at this job?",
  selectBtn6: "1-3 months",
  selectBtn7: "3-6 months",
  selectBtn8: "6+ months",
  yes: 'Yes',
  no: 'No',
  nextBtn: 'Next',
  title3: 'Tell us about your liabilities',
  liabilitiesTitle: 'How much is your total monthly financial obligations (total monthly loan installment) in Saudi riyals and number of years remaining for obligations',
  liabilitiesSliderTitle: 'Number of years remaining for obligations',
  liabilities1: 'Personal',
  liabilities2: 'Car',
  liabilities3: 'Mortgage',
  liabilities4: 'Credit card',
  liabilitiesTable: "Added liabilities",
  add: 'Add',
  valueTitle: 'Added liabilities',
  monthly: "Monthly",
  valueText: "months to complete",
  popupname: "Add liability",
  installment: 'Monthly installment',
  complete: "Months to complete",
  title4: 'SIMAH Record',
  simahText: 'Do you currently have defaults on payments with SIMAH?',
  simahValue1: 'Yes',
  simahValuw2: 'No',
  title5: "Your first Home without tax",
  infoHeader1: 'Royal Order No. (A \ 86) dated 4/18/1439 AH stipulates that',
  infoText1: "the state shall bear value-added tax on an amount not exceeding (1 million riyals) of the purchase price of a citizen’s first home in order to reduce the cost of owning a suitable home for citizens.",
  question: "Is this your first home?",
  value6: 'Yes, this is my first home',
  value7: "No, I already have a house before",
  title6: 'Housing support program',
  infoHeader2: 'The updated housing support program It is a way of facilitating home ownership by providing non-refundable financial support in a fixed amount of SAR 100,000 or SAR 150,000, according to the approved product matrix.',
  infoText2: "The Ministry of Housing provided a support amount of a certain percentage of the amount of profits, up to a maximum of 500,000 riyals of the total financing amount.",
  Eligible: "Are you eligible for the “Sakani” support program?",
  isMortgageReady: "Did you find a suitable property?",
  notSure: "Not Sure",
  moneyType: 'SAR',
  title7: 'Congratulations!',
  text1: 'Your maximum mortgage limit is',
  text2: 'You have no recommendations form banks at this time',
  text3: 'You need to pass KYC by Nafad, check your Simah report to get final offers from banks.',
  startBtn: "Start Now",
  Previous: 'Previous',
  preLastBtn: 'Calculate your mortgage',
  Emkan: "Emkan",
  lastText: "You’re pre-approved for up to:",
  lastText2: "We will contact you very soon",
  liabilitiesType1: 'With current liabilities',
  liabilitiesType2: 'Without liabilities',
  liabilitiesType3: "Maximum mortgage amount",
  Up_to: 'Up to',
congrat: "Congratulations!",
  advise: "You can now proceed with the Mortgage Expert service, which offers you advantages to access the most suitable financing through our experts. We are always here to assist you.",
  starWithExpert: "Start the Mortgage Expert service now",
  start: "Start the service",
  ammount: "Amount",
  month6: '6 month',
  year5: '5 year',
  jobTypeRequired: "Please select a job type.",
  employerNameRequired: "Please enter your employer's name.",
  militaryRankRequired: "Please select a military rank.",
  piloteRequired: "Please specify if you are a pilot.",
  netSalaryRequired: "Please enter your net salary.",
  salaryComparison: "Net salary cannot be less than basic salary.",
  basicSalaryRequired: "Please enter your basic salary.",
  jobDateRequired: "Please select a job start date.",
  salaryBankRequired: "Please select your salary bank.",
  liabilitiesRequired: "Please enter your liabilities",
  typeOfMortgage: 'Type of Mortgage',
  typeOfMortgageOptions: {
    readyUnit: 'Ready unit',
    saleOnTheMap: 'sale on the map',
    selfBuild: 'self-build',
    other: 'other',
  },
  typeOfLoansOptions: {
    personalLoan: 'Personal loan',
    mortgageLoan:'Mortgage Loan',
    carLoan: 'Car loan',
    EmkanLoan:'Emkan Loan',
    creditCardLoan:'Credit Card loan',
    other: 'other',
  },
  doYouHaveAnyLoans:'Do you have any loans?',
  typeOfLoans:'Type of Loans',
  purchaseIntentionsTitle:'Complete the following details and we will guide you to the perfect property for you.',
  whereAreYouInYourHomeBuyingJourney:'Where are you in your home-buying journey?*',
  whereAreYouInYourHomeBuyingJourneyOptions:{
    imThinkingAboutBuying:'I\'m thinking about buying',
    tourOpenHouse:'Touring open houses',
    makeOfferOnProperty: "I would like to confirm the possibility of purchasing",
    iHaveSignedPurchaseContract: "I have reserved the unit",    
  },

  
  welcomeMessage: "Welcome to the Mortgage Expert Service",
benefitsDescription: "Take advantage of the benefits of connecting with a mortgage expert and get the best offer:",
  benefitsList: {
    text1: "A suitable financing amount based on your needs",
    text2: "A suitable monthly installment based on your budget",
    text3: "A suitable profit margin based on your financing"
  },
additionalBenefits: "Additionally, enjoy extra benefits, including ongoing monitoring of your financing by the mortgage expert, as well as handling all financial procedures on your behalf."
,



text1: "Take advantage of the benefits of consulting with a real estate financing expert and get the best financing offer from 16 financing institutions to provide:",
text2: "There are additional benefits of having your financing updates followed up by the real estate financing expert continuously, as well as handling all procedures on your behalf.",
text3: "Additional Benefits:",
text4: "Continuous follow-up on your financing updates by the real estate financing expert.",
text5: "Handling real estate financing procedures on your behalf until the property is transferred.",
text6: "Managing the entire financing process and providing the best options that suit you.",
text7: "No, thank you. I'm not interested right now.",
text8: "We are happy to serve you and fulfill your desire to experience the service.",
text9: "The service fee is 49 SAR, including VAT.",
text10: "Pay now to start the service and submit the request.",
text11: "Choose your preferred payment method.",
text12: "Pay now",
text13: "Cancel",
text14: "Do you have a message or inquiry to send with your request?",
text15: "Optional",


typesOfInterestedOptions: {
  apple_pay: "Apple Pay",
  google_play: "Google Pay",
  card: "Payment Card"
},
  thanks: "Thank you",
  expertWillContact: "The financial expert will contact you to provide the service during working hours from Sunday to Thursday (excluding official holidays) from 9 AM to 5 PM.",
  maxExpert: "At most within 48 hours of receiving the request",
  thanksBtn: "Thank you, End",
  typesOfInterestedOfExpertOrder:{ 
    paymentStatus: "Payment Done",
    paymentDecline: "I do not wish to pay for the service",
    inquiry: "I have an inquiry"
  },
  exprienece: "Are you interested in consulting a mortgage expert?",
  whenAreYouLookingToBuy:"When are you looking to buy?*",
  whenAreYouLookingToBuyOptions:{
    zeroToThreeMonths:'0 - 3 months',
    fourToSixMonths:'4 - 6 months',
    sixMonthPlus:'+6 months',
    notSure:'Not sure',
  },
  howWillYouUseYourNewHome:"How will you use your new home?*",
  howWillYouUseYourNewHomeOptions:{
    primaryResidence:'Primary residence',
    secondaryResidence:'Secondary residence',
    investmentProperty:'Investment property',
  },
  pleaseAnswerAllRequiredQuestions:'please Answer All Required Questions',
  welcomeMessageExpert: "Welcome to the Mortgage Expert Service",
  serviceDescription: "We are pleased to serve you and enrich your service experience.",
  serviceFee: "The service fee is 49 SAR, inclusive of VAT.",
  actionPrompt: "To start the service and submit your request, click on the following link:",
  interestQuestion: "Are you interested in consulting a mortgage expert?",
  text31: "Enter the contact number of the sales representative for the real estate unit you wish to purchase and enjoy:",
text32: "1- Competitive financing offers from all banks.",
text33: "1- A free service to complete financing procedures at no additional cost.",
text34: "1- Savings of up to 4,000 SAR on bank administrative fees.",
text35: "The mobile number of the sales representative for any unit you wish to purchase*",
text36: "Enter the mobile number of the sales representative.",
text37: "Submit Request",
text38: "You still haven't found a property?",
text39: "Financing Calculator",
text30: "We will work on finding the right real estate financing for you and your needs.",
text40: "The number must start with '05' and consist of exactly 10 digits.",
text42: "Finish",

text45: "Your Mobile Number",
text46: "(Buyer)",
text47: "Mobile Number Of",
text49: "Real Estate Marketer Or Property Owner",
text50:"Seller number must be 9 digits and start with 5.",
text51:"Buyer number must be 9 digits and start with 5.",
text52:"Seller and Buyer numbers cannot be the same.",
text53: "Enter the financing calculator and know your financing limit",

text54: "Your request has been successfully submitted!",
text55: "We will contact you soon to provide the best financing offers. Please prepare the following:",
text56: "1. Salary certificate",
text57: "2. National ID",
text58: "3. Property deed",
text59: "Did you visit the property? Are you serious about buying?",
text60: "If you find the property, enter the seller's number ",
text61: "Own mobile number is not allowed",

text62: " and get:",
text63: " 3- Save up to",
text64: " 4,000 SAR",
text65: " in bank administrative fees",
text67: " Go to",
text68: " Finance Calculator",
text69: " and know your financing limit",
text70: "This field is required",
text71: "I confirm that i have visited the property and i am already in contact with Broker or Property Owner ( Seller ).",
text72: "Confirm you've visited the property and contacted the broker/owner.",















}
