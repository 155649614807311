export const translatioanAb = {
  title1: "احصل على أفضل أسعار التمويل العقاري لمنزل أحلامك",
  infoTitle:
    "يعتمد الحد الأعلى لتمويلك العقاري وقيمة الأقساط الشهرية والمعدل على:",
  infoItem1: "قطاع العمل",
  infoItem2: "الراتب",
  infoItem3: "أهلية الدعم الحكومي",
  infoText: "ابدأ الآن لتتعرف على فرص التمويل المتاحة لك",
  btn1: "ابدأ الآن",
  agree:" أوافق على",
  termsAndConditions:"الشروط والأحكام",
  agreeSIMAH:"SIMAH أوافق على استعادة بياناتي من سمة",
  title2: "فضلاً أخبرنا عن عملك",
  selectButton1: "في أي قطاع تعمل؟",
  selectBtn1: "حكومي مدني",
  selectBtn2: "حكومي عسكري",
  selectBtn3: "قطاع خاص (شركات صغيرة ومتوسطة)",
  selectBtn4: "قطاع خاص (شركة كبرى)",
  selectBtn5: "متقاعد",
  Rank: "الرتبة",
  Pilote: "هل أنت طيار؟",
  inputTitle1: "اكتب اسمك",
  placeHolder1: "عبدالرحمن صالح",
  inputTitle2: "راتبك الأساسي",
  placeHolder2: "اكتب راتبك هنا",
  inputTitle3: "راتبك الشهري المودع في الحساب البنكي بعد خصم التأمينات",
  placeHolderInfo: "راتبك الشهري المودع في الحساب البنكي بعد خصم التأمينات",
  placeHolder3: "المبلغ الذي يحول الى حسابك البنكي بشكل شهري بعد خصم التأمينات",
  inputTitle4: "تاريخ بدء العمل",
  inputTitle5: "اسم البنك الذي يحول إليه راتبك الشهري",
  placeHolder5: "فضلاً اختر اسم البنك",
  selectButton2: "منذ متى وأنت في هذه الوظيفة؟",
  selectBtn6: " ١-٣ أشهر ",
  selectBtn7: " ۳-٦ أشهر ",
  selectBtn8: "٦+ أشهر",
  yes: "نعم",
  no: "لا",
  nextBtn: "التالي",
  title3: "أخبرنا عن التزاماتك",
  liabilitiesTitle:
    "ما هو إجمالي التزاماتك المالية الشهرية بالريال السعودي وعدد السنوات المتبقية لتلك الالتزامات؟",
  liabilitiesSliderTitle: "كم عدد السنوات المتبقية للالتزامات؟",
  liabilities1: "شخصي",
  liabilities2: "سيارة",
  liabilities3: "عقاري",
  liabilities4: "بطاقة إئتمان",
  add: "أضف",
  monthly: "شهريا",
  valueText: " أشهر للإكمال",
  popupname: "إضافة المسؤولية",
  installment: "القسط الشهري",
  complete: "عدد الأشهر المتبقية",
  title4: "سجل سمة",
  simahText: "هل لديك حاليًا تخلف عن سداد المدفوعات مع سمة؟",
  simahValue1: "نعم",
  simahValuw2: "لا",
  liabilitiesTable: "الالتزامات المضافة",
  title5: "أحقية برنامج الدعم السكني",
  infoHeader1: "الأمر الملكي رقم (أ/86) وتاريخ 18/04/1439هـ ينص على أنه",
  infoText1:
    "تتحمل الدولة ضريبة القيمة المضافة على مبلغ لا يتجاوز (مليون ريال) من سعر شراء المسكن الأول للمواطن، وذلك لتخفيض تكلفة تملك المسكن المناسب للمواطنين.",
  question: "هل هذا مسكنك الأول؟",
  value6: "نعم، هذا هو بيتي الأول",
  value7: "لا، لدي منزل بالفعل",
  moneyType: "ريال",
  title6: "أحقية الدعم",
  infoHeader2:
    "برنامج الدعم السكني المحدث هو أحد الحلول المقدمة لتسهيل امتلاك المسكن للمستفيد وذلك من خلال تقديم دعم مالي غير مسترد بمبلغ ثابت 100 أو 150 ألف ريال حسب مصفوفة المنتجات المعتمدة",
  infoText2:
    "وقدمت وزارة الإسكان مبلغ دعم بنسبة معينة من مبلغ الأرباح وبحد أقصى 500,000 ريال من إجمالي مبلغ التمويل.",
  Eligible: "هل أنت مستحق لبرنامج الدعم'سكني'؟",
  isMortgageReady: "هل وجدت العقار المناسب؟",
  notSure: "غير متأكد",
  title7: "تهانينا!",
  text1: "الحد الأقصى للتمويل العقاري هو",
  text2: "ليس لديك توصيات من البنوك في هذا الوقت.",
  text3:
    "تحتاج إلى اجتياز KYC by Nafad، والتحقق من تقرير سمة الخاص بك للحصول على العروض النهائية من البنوك.",
  startBtn: "العودة الي التطبيق",
  Previous: "السابق",
  preLastBtn: "احسب تمويلك",
  Emkan: "إمكان",
  lastText: "حاسبة التمويل العقاري",
  lastText2: "سوف نتواصل معك قريباً جداً",
  liabilitiesType1: "مبلغ التمويل مع التزاماتك",
  liabilitiesType2: "حد التمويل العقاري الخاص بك هو ",
  liabilitiesType3: "الحد الأقصى لمبلغ التمويل العقاري",
  Up_to: "حتى",
  congrat: "مبروك عليك!", 
  advise: "بإمكانك الآن المضي قدمًأ مع خدمة خبير التمويل العقاري والتي تقدم لك مميزات للوصول للتمويل الأنسب من قبل خبرائنا, فنحن في خدمتك في أي وقت",
  starWithExpert:"ابدأ الآن خدمة خبير التمويل العقاري",
  start: "ابدأ الخدمة",
  ammount: "المبلغ",
  month6: "6 أشهر",
  year5: "5 سنوات",
  jobTypeRequired: "يرجى اختيار نوع الوظيفة.",
  employerNameRequired: "يرجى إدخال اسمك",
  militaryRankRequired: "يرجى اختيار الرتبة العسكرية.",
  piloteRequired: "يرجى تحديد ما إذا كنت طيارًا.",
  netSalaryRequired: "يرجى إدخال راتبك الشهري.",
  salaryComparison: "الراتب الصافي لا يمكن أن يكون أقل من الراتب الأساسي.",
  basicSalaryRequired: "يرجى إدخال راتبك الأساسي.",
  jobDateRequired: "يرجى اختيار تاريخ بدء الوظيفة.",
  salaryBankRequired: "يرجى اختيار البنك الخاص براتبك.",
  liabilitiesRequired: "يرجى إدخال التزاماتك",
  typeOfMortgage: 'نوع التمويل العقاري',
  typeOfMortgageOptions: {
    readyUnit: 'وحدة جاهزة',
    saleOnTheMap: 'بيع على الخريطة',
    selfBuild: 'بناء ذاتي',
    other: 'اخرى',
  },
  typeOfLoansOptions: {
    personalLoan: 'قرض شخصي',
    mortgageLoan: 'قرض عقاري',
    carLoan: 'قرض السيارة',
    EmkanLoan: 'إمكان',
    creditCardLoan: 'بطاقة ائتمان',
    other: 'اخرى',
  },
  doYouHaveAnyLoans: 'هل لديك أي قروض ؟',
  typeOfLoans: 'نوع القرض',
  purchaseIntentionsTitle: 'أكمل التفاصيل التالية وسنقوم بإرشادك إلى العقارات المثالية لك.',
  whereAreYouInYourHomeBuyingJourney: 'في أي مرحلة من مراحل الشراء تتواجد الآن؟',
  whereAreYouInYourHomeBuyingJourneyOptions: {
    imThinkingAboutBuying: 'ما زلت أفكر في الشراء',
    tourOpenHouse: 'أستكشف الفرص المتاحة في السوق',
    makeOfferOnProperty: 'ارغب بالتأكد من امكانية الشراء',
    iHaveSignedPurchaseContract: 'قمت بحجز الوحده'
  },
  typesOfInterestedOptions:{
    apple_pay:"آبل باي",
    google_play: "جوجل باي",
    card: "بطاقة الدفع"
  },
  typesOfInterestedOfExpertOrder: {
    paymentStatus: "تم الدفع",
    paymentDecline: "لا أرغب في الدفع للخدمة",
    inquiry: "لدي استفسار"
  
  },

  
  text1: "استفد من مزايا خدمة التواصل مع خبير التمويل العقاري واحصل على أفضل عرض تمويلي من بين 16 جهة تمويل لتوفير:",
  text2: "وهناك مزايا إضافية من متابعة التحديثات للتمويل الخاص بك من قبل خبير التمويل العقاري أول بأول وكذلك متابعة كافة الإجراءات بالنيابة عنك",
  text3:"مزايا إضافية:",
  text4:"متابعة التحديثات للتمويل الخاص بك من قبل خبير التمويل العقاري أول بأول",
  text5: "متابعة إجراءات التمويل العقاري بالنيابة عنك وصولًا إلى الإفراغ العقاري",
   text6:"تولي إجراءات التمويل كاملة وتقديم أفضل الخيارات المناسبة لك",
  text7:"لا شكرًا، غير مهتم الآن",
  text8:"يسعدنا خدمتك رغبتك في تجربة الخدمة",
  text9:"رسوم الخدمة 49 ريال شاملة ضريبة القيمة المضافة",
  text10: "ادفع الآن لبدء الخدمة وتقديم الطللب",
  text11: "اختر طريقة الدفع التي تفضلها",
  text12: "ادفع الان",
  text13: "إلغاء",
  text14: "هل لديك رسالة أو استفسار ترسله مع طلبك؟",
  text15: "اختياري",



  thanks: " شكرا لك, تم السداد بنجاح",
  expertWillContact: "سيتواصل معك الخبير المالي لتقديم الخدمة خلال ساعات العمل من الأحد إلى الخميس (عدا فترات الإجازات الرسمية) من الساعة التاسعة صباحًا حتى الخامسة مساء",
  maxExpert: "بحد أقصى خلال 48 ساعة من استلام الطلب",
  thanksBtn: "شكرًا, إنهاء",
  exprienece: "هل أنت مهتم باستشارة خبير التمويل العقاري؟",

  welcomeMessage: "أهلا بك في خدمة خبير التمويل العقاري",
  benefitsDescription: "استفد من مزايا خدمة التواصل مع خبير التمويل العقاري واحصل على أفضل عرض:",
  benefitsList: {
   text1: "مبلغ تمويل مناسب حسب احتياجاتك",
    text2: "قسط شهري مناسب حسب ميزانيتك",
    text3: "هامش ربح مناسب حسب تمويلك"
  },
  additionalBenefits: "وهناك مزايا إضافية مع متابعة التحركات للتمويل الخاص بك من قبل خبير التمويل العقاري أولًا بأول وكذلك متابعة كافة الإجراءات المالية نيابةً عنك.",

  whenAreYouLookingToBuy: "متى تريد الشراء؟",
  whenAreYouLookingToBuyOptions: {
    zeroToThreeMonths: '0 - 3 شهور',
    fourToSixMonths: '4 - 6 شهور',
    sixMonthPlus: '+ 6 شهور',
    notSure: 'غير متأكد',
  },
  howWillYouUseYourNewHome: "ما هو الغرض من شراء العقار؟",
  howWillYouUseYourNewHomeOptions: {
    primaryResidence: 'مسكن أول (أساسي)',
    secondaryResidence: 'مسكن ثانٍ (ثانوي)',
    investmentProperty: 'الاستثمار',
  },
  pleaseAnswerAllRequiredQuestions: 'الرجاء الإجابة عن جميع الأسئلة المطلوبة',
  welcomeMessageExpert: "أهلا بك في خدمة خبير التمويل العقاري",
  serviceDescription: "يسعدنا خدمتك وتثريتك في تجربة الخدمة",
  serviceFee: "رسوم الخدمة 49 ريال شاملة ضريبة القيمة المضافة",
  actionPrompt: "لبدء الخدمة وتقديم الطلب، اضغط على الرابط التالي:",
  interestQuestion: "هل أنت مهتم باستشارة خبير التمويل العقاري؟",
  text30: 'سنعمل على إيجاد التمويل العقاري المناسب لك ولإحتياجاتك',
  text31: '‎أدخل رقم مسؤول البيع للوحدة العقارية التي ترغب بشرائها واستمتع بـ',
  text32: '1-⁠  ⁠عروض تمويل تنافسية من جميع البنوك.',
  text33: '2-⁠  ⁠⁠خدمة مجانية لإنهاء إجراءات التمويل دون تكلفة إضافية.',
  text34: '3-⁠  ⁠⁠توفير يصل إلى 4,000 ريال في الرسوم الإدارية البنكية',
  text35: 'رقم جوال مسؤول البيع لأي وحدة ترغب في شرائها*',
  text36: 'أدخل رقم جوال مسؤول البيع',
  text37: 'إرسال الطلب',
  text38: 'باقي ما لقيت عقار؟',
  text39: 'حاسبة التمويل',
  text40: "يجب أن يبدأ الرقم بـ '05' ويتكون من 10 أرقام فقط.",
  text41: "تم استلام الطلب بنجاح سيتم بالتواصل بكم قريبا",
  text42: "إنهاء",
  text45: "رقم الجوال ",
  text46: "الخاص بك (المشتري)",
  text47: "رقم جوال ",
  text49: "المسوق العقاري أو المالك",
  text50: "يجب أن يكون رقم البائع مكونًا من 9 أرقام ويبدأ بالرقم 5.",
text51: "يجب أن يكون رقم المشتري مكونًا من 9 أرقام ويبدأ بالرقم 5.",
text52: "لا يمكن أن يكون رقم البائع ورقم المشتري متطابقين.",
text53: 'ادخل على حاسبة التمويل واعرف حد تمويلك',
text54: "تم إرسال طلبك بنجاح!",
text55: "سنتواصل معكم في أقرب وقت لتوفير أفضل العروض التمويلية, نرجو تجهيز:",
text56: "1. تعريف راتب",
text57: "2. والهوية الوطنية",
text58: "3. وصك العقار",
text59: "زرت عقار وصامل تشتري؟",
text60: "في حال وجدت العقار ضع رقم البائع ",
text61: ' يرجى عدم استخدام رقم الجوال الشخصي،  نأمل إدخال رقم البائع للمتابعة.  ',


text62: "واحصل على : ",
text63: "3-⁠ ⁠⁠توفير يصل إلى",
text64: " 4,000  ريال" ,
text65: " في الرسوم الإدارية البنكية",
text67: " ادخل على",
text68: " حاسبة التمويل",
text69: " واعرف حد تمويلك",
text70: "هذا الحقل مطلوب",
text71:"أؤكد انني قمت بزيارة العقار وانا بالفعل على تواصل مع الوسيط او مالك العقار",
text72: "أكد أنك زرت العقار وتواصلت مع الوسيط/المالك",







}

